<template>
  <div class="container">
    <TimesheetList
        :current-page="currentPage"
        :per-page="perPage"
        :per-page-options="perPageOptions"
        :search-query="searchQuery"
        :filters="filters"
        :sort-by="sortBy"
        :is-sort-dir-desc="isSortDirDesc"
        @add="openAddForm"
        @edit="handleEdit"
        @delete="handleDelete"
        @approve="approveTimesheet"
    />
    <TimesheetForm
        v-if="showForm"
        :initial-entries="selectedEntries"
        :selected-week="selectedWeek"
        :last-entered-week="lastEnteredWeek"
        :is-edit="isEdit"
        :show-toast="showToast"
        @save="saveTimesheet"
        @close="closeForm"
    />
  </div>
</template>

<script>
import TimesheetList from "@/views/apps/timesheet/TimesheetList.vue";
import TimesheetForm from "@/views/apps/timesheet/TimesheetForm.vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {getUserData} from "@/auth/utils";

export default {
  components: {TimesheetList, TimesheetForm},
  data() {
    return {
      showForm: false,
      isEdit: false,
      selectedId: null,
      selectedWeek: '',
      selectedEntries: [],
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50],
      searchQuery: '',
      filters: {
        week: '', // Filter per week
      },
      sortBy: 'created_by',
      isSortDirDesc: true,
    };
  },
  watch: {
    getError(val) {
      if (val) {
        this.showToast(val.message, "AlertTriangleIcon", "danger");
      }
    }
  },

  computed: {
    timesheets() {
      return store.getters['timesheet/all'];
    },
    lastEnteredWeek() {
      if (this.timesheets.length === 0 || this.$can('approved_timesheet', 'Feuilles de temps')) return ''
      return this.timesheets.reduce((latest, current) => (current.week > latest ? current.week : latest), '')
    },
    getError() {
      return store.getters['timesheet/errors'];
    }
  },
  mounted() {
    this.fetchTimesheets();
  },

  methods: {
    async fetchTimesheets() {
      await store.dispatch('timesheet/fetchTimesheets', {
        week: this.filters.week,
        page: this.currentPage,
        perPage: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
    },
    getDefaultEntries() {
      return [
        {day: 'Lundi', activities: [{activity: '', description: '', hours: 0}], totalHours: 0},
        {day: 'Mardi', activities: [], totalHours: 0},
        {day: 'Mercredi', activities: [], totalHours: 0},
        {day: 'Jeudi', activities: [], totalHours: 0},
        {day: 'Vendredi', activities: [], totalHours: 0},
        {day: 'Samedi', activities: [], totalHours: 0},
        {day: 'Dimanche', activities: [], totalHours: 0},
      ];
    },
    openAddForm() {
      this.isEdit = false;
      this.selectedEntries = this.getDefaultEntries();
      this.showForm = true;
    },

    handleEdit(id) {
      const timesheet = this.timesheets.find(ts => ts.uid === id);
      this.selectedId = id;
      this.selectedWeek = timesheet.week;

      store.commit('timesheet/SET_CURRENT_TIMESHEET', timesheet);

      // si tu veux être prudent et éviter les références directes :
      this.selectedEntries = timesheet.entries.map(entry => ({
        ...entry,
        activities: entry.activities.map(act => ({...act}))
      }));

      this.isEdit = true;
      this.showForm = true;
    },

    async handleDelete(id) {
      await axios.delete(`/timesheets/${id}`);
      this.timesheets = this.timesheets.filter((ts) => ts.id !== id);
    },
    async approveTimesheet(id) {
      const timesheet = this.timesheets.find((ts) => ts.uid === id);
      const self = this
      const swal = this.$swal;

      swal.fire({
        title: 'Approuver la feuille de temps',
        text: `Voulez-vous approuver la feuille de temps du ${timesheet.week} de ${timesheet.user} ?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        confirmButtonText: 'Oui, approuver',
        cancelButtonText: 'Annuler',
        preConfirm: async () => {
          timesheet.status = 'validated';
          const {data, status } = await store.dispatch('timesheet/updateTimesheet', timesheet);
          if(status != 201) {
              if (typeof data.error === 'object' && Object.keys(data.error).length > 0) {
                swal.showValidationMessage("Une erreur est survenue lors de l'approbation.");
              } else if (typeof data.error === 'string') {
                swal.showValidationMessage(data.error);
              }
            return;
          } else {
          swal.fire({
            icon: 'success',
            title: 'Approuvé',
            text: 'La feuille de temps a été approuvée avec succès.',
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
          });
          }
        }
      })
    },
    async saveTimesheet({week, entries}) {
      const totalWeeklyHours = entries.reduce((acc, curr) => acc + curr.totalHours, 0);
      const payload = {
        week: week,
        date: new Date().toISOString().split('T')[0],
        total_hours_week: totalWeeklyHours,
        'status': 'pending',
        entries,
      };
      try {
        // if (this.isEdit) {
        //   const response = await axios.put(`/timesheets/${this.selectedId}`, payload);
        //   this.timesheets = this.timesheets.map(ts =>
        //       ts.uid === this.selectedId ? response.data : ts
        //   );
        // } else {
        // }
        await store.dispatch('timesheet/addTimesheet', payload);
      } catch (error) {
        this.showToast(error.response.data.message, "AlertTriangleIcon", "danger");
      } finally {
        this.closeForm();
        await this.fetchTimesheets();
      }
    },

    closeForm() {
      this.showForm = false;
      this.isEdit = false;
      this.selectedId = null;
      this.selectedWeek = '';
      store.commit('timesheet/SET_CURRENT_TIMESHEET', null);
      this.selectedEntries = this.getDefaultEntries();
      this.resetError();
    },
    resetError() {
      store.commit('timesheet/SET_ERRORS', null);
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
  },
};
</script>
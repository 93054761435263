<template>
  <div>
    <!-- Filtres -->
    <timesheets-list-filters :weekFilter="filters.week"/>

    <!-- Tableau avec recherche et pagination -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Nombre d'éléments par page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="mx-1"
            />
            <label>Feuilles</label>
          </b-col>
          <!-- Recherche -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  placeholder="Rechercher..."
                  class="mr-2"
              />
              <b-button variant="primary" @click="openAddForm">
                <span class="align-middle text-nowrap"> <feather-icon class="mr-50" icon="PlusIcon"/> Ajouter</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Tableau des feuilles de temps -->
      <b-table
          ref="refTimesheetsTable"
          :items="timesheets"
          :fields="tableColumns"
          primary-key="id"
          responsive
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          empty-text="Aucune feuille trouvée"
          hover

      >
        <!-- Column: Status -->
        <template #cell(approved)="data">
          <b-badge :variant="data.item.approved ? 'primary' : 'warning'" class="text-capitalize">
            {{ data.item.approved ? "Approuvé" : "En attente" }}
          </b-badge>
        </template>
        <template #cell(user)="data">
          <span>{{ data.item.user}}</span>
        </template>

        <!-- Action Buttons -->
        <template #cell(actions)="data">
          <div v-show="!data.item.approved" class="text-nowrap">
            <!-- Edit -->
            <feather-icon
                :id="`timesheet-${data.index}-edit-icon`"
                v-if="$can('edit_timesheet', 'Feuilles de temps') && data.item.user == currentUserName"
                icon="EditIcon"
                size="16"
                class="cursor-pointer text-warning mr-1"
                @click="editTimesheet(data.item.uid)"
            />
            <b-tooltip title="Modifier" v-if="$can('edit_timesheet', 'Feuilles de temps') && data.item.user == currentUserName"
                       :target="`timesheet-${data.index}-edit-icon`"/>

            <!-- Approve -->
            <feather-icon
                :id="`timesheet-${data.index}-approve-icon`"
                v-if="$can('approved_timesheet', 'Feuilles de temps')"
                icon="PauseCircleIcon"
                size="16"
                class="text-success cursor-pointer"
                @click="$emit('approve', data.item.uid)"
            />
            <b-tooltip title="Approuver" v-if="$can('approved_timesheet', 'Feuilles de temps')"
                       :target="`timesheet-${data.index}-approve-icon`"/>

            <!-- Supprimer -->
            <feather-icon
                :id="`timesheet-${data.index}-delete-icon`"
                v-if="$can('delete_timesheet', 'Feuilles de temps') && data.item.user == currentUserName"
                icon="TrashIcon"
                size="16"
                class="cursor-pointer text-danger ml-1"
                @click="deleteTimesheet(data.index)"
            />
            <b-tooltip title="Supprimer" v-if="$can('delete_timesheet', 'Feuilles de temps') && data.item.user == currentUserName"
                       :target="`timesheet-${data.index}-delete-icon`"/>
          </div>
        </template>
        <template #cell(details)="row">
          <b-button @click="row.toggleDetails" size="sm" variant="outline-primary">
            {{ isRowShown(row.item.uid) ? 'Cacher' : 'Voir détails' }}
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <b-list-group flush>
              <b-list-group-item v-for="(entry, idxEntry) in row.item.entries" :key="idxEntry">
                <strong>{{ entry.day }} - Total : {{ entry.total_hours }}h</strong>
                <ul>
                  <li v-for="(activity, idxAct) in entry.activities" :key="idxAct">
                    {{ activity.activity }} : {{ activity.hours }} heures - {{ activity.description }}
                  </li>
                </ul>
              </b-list-group-item>
            </b-list-group>
          </b-card>

        </template>


      </b-table>

      <!-- Pagination -->
      <b-row class="align-items-center mx-2 mt-2">
        <!-- Informations de pagination -->
        <b-col cols="6">
          <span>Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur {{ dataMeta.of }}</span>
        </b-col>
        <!-- Contrôles de pagination -->
        <b-col cols="6" class="d-flex justify-content-end">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalTimesheets"
              :per-page="perPage"
              first-number
              last-number
              class="my-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TimesheetsListFilters from './TimesheetsListFilters.vue';
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BTooltip,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import vSelect from 'vue-select'
import store from "@/store";
import moment from "moment";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BTooltip,
    BBadge, BPagination, BTable, BFormInput, BListGroup, BListGroupItem, BButton,
    BCol, BRow, BCard, vSelect, TimesheetsListFilters
  },
  props: {
    filters: {
      type: Object,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    perPageOptions: {
      type: Array,
      required: true
    },
    searchQuery: {
      type: String,
      required: true
    },
    sortBy: {
      type: String,
      required: true
    },
    isSortDirDesc: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tableColumns: [
        {key: 'week', label: 'Semaine du', sortable: true, formatter: (value) => this.getFirstDateOfWeek(value)},
        {key: 'user', label: 'Agents'},
        {key: 'total_hours_week', label: 'Total heures'},

        {key: 'approved', label: 'Statut', sortable: true},
        {key: 'details', label: 'Détails', sortable: false},

        {key: 'actions', label: 'Actions'},
      ],
      openRowIds: [], // watch opened ids
    };
  },
  watch: {
    filteredTimesheets: {
      handler: 'refetchTimesheetsData',
      deep: true,
      immediate: true,
    }
  },
  computed: {
    timesheets() {
      return store.getters['timesheet/all'];
    },
    totalTimesheets() {
      return store.getters['timesheet/meta']?.total;
    },
    filteredTimesheets() {
      return [
          this.currentPage,
          this.perPage,
          this.sortBy,
          this.isSortDirDesc,
          this.searchQuery,
          this.filters
      ];
    },
    dataMeta() {
      const itemsCount = store.getters['timesheet/meta']?.total !== undefined ? store.getters['timesheet/meta'].total : 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (itemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + itemsCount,
        of: this.totalTimesheets,
      };
    },
    currentUserName() {
      return getUserData()?.name ?? '';
    }
  },
  methods: {
    refetchTimesheetsData() {
      if(this.$refs.refTimesheetsTable)
        this.$refs.refTimesheetsTable.refresh();
    },
    // toggleDetails(item) {
    //   console.log('item', item)
    //   const index = this.openRowIds.indexOf(item.id)
    //
    //   if (index >= 0) {
    //     this.openRowIds.splice(index, 1)
    //   } else {
    //     this.openRowIds.push(item.id)
    //   }
    // },
    isRowShown(id) {
      return this.openRowIds.includes(id)
    },
    openAddForm() {
      this.$emit('add');
    },
    editTimesheet(id) {
      this.$emit('edit', id);
    },
    deleteTimesheet(id) {
      this.timesheets = this.timesheets.filter((ts) => ts.id !== id);
      axios.delete(`/timesheets/${id}`);
    },
    getFirstDateOfWeek(weekString) {
      return moment(weekString, 'GGGG-[W]WW').startOf('isoWeek').format('DD-MM-YYYY');
    }
,
  },
};
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<template>
  <validation-observer ref="refFormObserver" #default="{ invalid }">
    <b-modal
        v-model="show"
        size="lg"
        :title="isEdit ? 'Modifier une Feuille de Temps' : 'Ajouter une Feuille de Temps'"
        :ok-title="isEdit ? 'Modifier' : 'Enregistrer'"
        :ok-disabled="invalid"
        cancel-variant="outline-secondary"
        @ok="onSubmit"
        @hidden="onClose"
    >
      <b-form @submit.stop.prevent="saveEntries">
        <validation-provider v-slot="{ errors }" name="timesheet-week" rules="required">
          <div class="mb-3">
            <label for="timesheet-week">Semaine concernée :</label>
            <b-form-input
                type="week"
                id="timesheet-week"
                v-model="localWeek"
                :min="minWeekAllowed"
                :class="{'is-invalid': errors.length > 0}"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>

        <div v-for="(entry, dayIdx) in localEntries" :key="dayIdx" class="mb-3">
          <b-card no-body>
            <b-card-header @click="entry.open = !entry.open"
                           class="d-flex justify-content-between align-items-center cursor-pointer">
              {{ entry.day }} - {{ entry.totalHours }} heures
              <b-icon :icon="entry.open ? 'chevron-up' : 'chevron-down'"/>
            </b-card-header>

            <b-collapse v-model="entry.open">
              <b-card-body>

                <div class="border rounded p-2 mb-3" v-for="(activity, actIdx) in entry.activities" :key="actIdx">
                  <div class="row">
                    <!-- Sélection activité -->
                    <div class="col-md-5 mb-2">
                      <validation-provider v-slot="{ errors }" name="activity" rules="required">
                        <label for="activity">Activité</label>
                        <v-select
                            :options="availableActivities(dayIdx, activity)"
                            v-model="activity.selectedActivity"
                            label="label"
                            placeholder="Choisir une activité"
                            :class="{'is-invalid': errors.length > 0}"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <!-- Sélection Projet (visible seulement si activité type projet) -->
                    <div class="col-md-5 mb-2"
                         v-if="activity.selectedActivity && activity.selectedActivity.type === 'project'">
                      <validation-provider name="project" rules="required" v-slot="{ errors }">

                        <label>Projet</label>
                        <v-select
                            :options="projects"
                            v-model="activity.selectedProject"
                            label="code"
                            placeholder="Choisissez un projet"
                            :class="{'is-invalid': errors.length > 0}"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </div>

                    <!-- Nombre d'heures -->
                    <div class="col-md-2 mb-2">
                      <validation-provider name="hour" rules="required|numeric|min-value:1" v-slot="{ errors }">
                        <label>Heures</label>
                        <b-form-input
                            type="number"
                            min="0"
                            placeholder="Nombre d'heures"
                            v-model.number="activity.hours"
                            :class="{'is-invalid': errors.length > 0}"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </div>

                    <!-- Description détaillée -->
                    <div class="col-md-5 mb-2">
                      <label>Description détaillée</label>
                      <b-form-textarea
                          v-model="activity.description"
                          placeholder="Description détaillée..."
                          rows="2"
                      />
                    </div>
                  </div>

                  <!-- Icône suppression activité -->
                  <div class="text-right mt-2">
                    <b-button variant="outline-danger" size="sm" @click="removeActivity(dayIdx, actIdx)">
                      <feather-icon icon="XIcon"/>
                    </b-button>
                  </div>
                </div>

                <b-button variant="outline-primary" size="sm" @click="addActivity(dayIdx)">
                  Ajouter activité
                </b-button>

              </b-card-body>
            </b-collapse>

          </b-card>
        </div>

      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import axios from "@axios";
import vSelect from 'vue-select'
import {BForm, BFormInput, BButton, BCollapse, BCard, BCardHeader, BCardBody, BIcon, BFormTextarea} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {required, numeric, minValue} from "@validations";

import store from "@/store";


export default {
  name: "TimesheetForm",
  components: {
    BFormTextarea,
    'v-select': vSelect,
    ValidationObserver, ValidationProvider,
    BForm, BFormInput, BButton, BCollapse, BCard, BCardHeader, BCardBody, BIcon
  },

  props: {
    initialEntries: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectedWeek: {
      type: String,
      default: ''
    },
    lastEnteredWeek: {
      type: String,
      default: ''
    },
    showToast: {
      type: Function,
    }
  },
  data() {
    return {
      required, numeric, minValue,
      show: true, // Always display when it mounted
      localEntries: this.initialEntries.map(entry => ({...entry, open: false})),
      localWeek: '', // current week selected
      projects: [],
      fetchingProjects: false,
      fetchingActivities: false,
    };
  },
  computed: {
    minWeekAllowed() {
      return this.lastEnteredWeek || ''
    },
    activityOptions() {
      return store.getters['timesheet/activityOptions']
    },
    getError() {
      return store.getters['timesheet/errors'];
    }
  },

  watch: {
    localEntries: {
      handler(entries) {
        entries.forEach(entry => {
          entry.totalHours = entry.activities.reduce((acc, act) => acc + Number(act.hours || 0), 0)
        })
      },
      deep: true,
    },
    selectedWeek: {
      immediate: true,
      handler(val) {
        this.localWeek = val
      }
    },
    getError(val) {
      if (val) {
        this.handleErrors(val.error);
      }
    }
  },
  mounted() {
    this.fetchTimesheetActivities();
    this.fetchProjects()
  },


  methods: {
    async fetchTimesheetActivities() {
      this.fetchingActivities = true;
      try {
        await store.dispatch("timesheet/fetchActivityOptions")
      } catch (e) {
        console.log('Erreur lors de la récupération des activités :', e.response?.data?.message || e.message || 'Erreur inconnue.')
        this.showToast('Impossible de charger les activités. Réessayez plus tard.', 'AlertTriangleIcon', 'danger')
      } finally {
        this.fetchingActivities = false
      }
    },
    async fetchProjects() {
      this.fetchingProjects = true
      try {
        store.dispatch("project/allProjects").then((projects) => {
          this.projects = projects.map((p) => {
            let strippedHtml = p.brief.replace(/<[^>]+>/g, "");
            return {uid: p.uid, code: `${p.code} - ${strippedHtml}`, brief: p.brief};
          });
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des projets:', error)
        this.showToast('Impossible de charger les projets. Réessayez plus tard.', 'AlertTriangleIcon', 'danger')
      } finally {
        this.fetchingProjects = false
      }
    },
    onSubmit(ev) {
      ev.preventDefault(); // empêche la fermeture automatique du modal
      this.$refs.refFormObserver.validate().then(valid => {
        if (valid) {
          this.saveEntries(); // lance la sauvegarde uniquement si valide
        }
      });

    },

    addActivity(dayIdx) {
      this.localEntries[dayIdx].activities.push({
        selectedActivity: null,
        selectedProject: null,
        hours: 0,
        description: '',
      })
    },
    removeActivity(dayIdx, actIdx) {
      this.localEntries[dayIdx].activities.splice(actIdx, 1)
    },
    availableActivities(dayIdx, currentActivity) {
      // Récupérer toutes les activités déjà sélectionnées pour le jour actuel.
      const selectedSlugs = this.localEntries[dayIdx].activities
          .map(a => a.selectedActivity && a.selectedActivity.slug)
          .filter(slug => slug && slug !== (currentActivity.selectedActivity && currentActivity.selectedActivity.slug))

      // Retourner la liste d'activités initiale filtrée.
      return this.activityOptions.filter(option => !selectedSlugs.includes(option.slug))
    },
    saveEntries() {
      if (this.localWeek < this.minWeekAllowed) {
        alert('Impossible de choisir une semaine antérieure à la semaine précédente.')
        return
      }

      for (const dayEntry of this.localEntries) {
        for (const activity of dayEntry.activities) {
          if (activity.selectedActivity && activity.selectedActivity.type === 'project' && !activity.selectedProject) {
            alert('Merci de sélectionner un projet pour les activités de type projet.')
            return
          }
          if (activity.hours && activity.hours <= 0) {
            alert("Merci d'entrer une heure valide.")
            return
          }
        }
      }

      this.$emit('save', {
        week: this.localWeek,
        entries: this.localEntries.map(dayEntry => ({
          day_name: dayEntry.day,
          day: this.getDateOfWeekDay(this.localWeek, dayEntry.day),
          activities: dayEntry.activities.map(act => ({
            uid: act.selectedActivity.uid,
            project: act.selectedActivity?.type === 'project' && act.selectedProject ? act.selectedProject.uid : null,
            hours: act.hours,
            description: act.description,
          })),
          total_hours: dayEntry.totalHours,
        })),
      })


      this.show = false;
    },
    cancel() {
      this.$emit("close");
      this.show = false;
    },
    onClose() {
      this.$emit("close");
      this.$nextTick(() => {
        if (this.$refs.refFormObserver) {
          this.$refs.refFormObserver.reset(); // reset les erreurs dès fermeture de la modal
        }
      });
    },
    handleErrors(errors) {
      for (const [field, message] of Object.entries(errors)) {
        this.$refs.refFormObserver.setErrors({[field]: message[0]});
      }
    },
    getDateOfWeekDay(weekString, dayName) {
      const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
      const dayIndex = daysOfWeek.indexOf(dayName);

      if (dayIndex === -1) {
        throw new Error(`Jour invalide : ${dayName}. Attendu : Lundi à Dimanche.`);
      }

      const [year, week] = weekString.split('-W').map(Number);
      const baseDate = new Date(Date.UTC(year, 0, 4));
      const dayOfTheWeek = baseDate.getUTCDay() || 7;

      // Premier lundi ISO de l'année
      const firstMondayISO = new Date(baseDate);
      firstMondayISO.setUTCDate(baseDate.getUTCDate() - dayOfTheWeek + 1);

      // Date exacte demandée par l'utilisateur
      const requestedDate = new Date(firstMondayISO);
      requestedDate.setUTCDate(firstMondayISO.getUTCDate() + (week - 1) * 7 + dayIndex);

      return requestedDate.toISOString().split('T')[0]; // format YYYY-MM-DD
    }
  },
};
</script>

<style scoped>
.modal-footer {
  justify-content: space-between;
}
</style>
<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtres
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label for="week-filter">Filtrer par Semaine :</label>
          <b-form-input
              id="week-filter"
              type="week"
              v-model="weekFilter"
              class="mt-1"
              @input="$emit('update:weekFilter', weekFilter)"
              placeholder="YYYY-WW"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {BCard, BCardBody, BCardHeader, BCol, BFormInput, BRow} from "bootstrap-vue";

export default {
  name: 'TimesheetsListFilters',
  components: {BCardHeader, BCard, BCardBody, BCol, BRow, BFormInput},
  props: {
    weekFilter: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
/* Adaptation minimale pour le filtre */
#week-filter {
  min-width: 200px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"size":"lg","title":_vm.isEdit ? 'Modifier une Feuille de Temps' : 'Ajouter une Feuille de Temps',"ok-title":_vm.isEdit ? 'Modifier' : 'Enregistrer',"ok-disabled":invalid,"cancel-variant":"outline-secondary"},on:{"ok":_vm.onSubmit,"hidden":_vm.onClose},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveEntries($event)}}},[_c('validation-provider',{attrs:{"name":"timesheet-week","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"timesheet-week"}},[_vm._v("Semaine concernée :")]),_c('b-form-input',{class:{'is-invalid': errors.length > 0},attrs:{"type":"week","id":"timesheet-week","min":_vm.minWeekAllowed},model:{value:(_vm.localWeek),callback:function ($$v) {_vm.localWeek=$$v},expression:"localWeek"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_vm._l((_vm.localEntries),function(entry,dayIdx){return _c('div',{key:dayIdx,staticClass:"mb-3"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between align-items-center cursor-pointer",on:{"click":function($event){entry.open = !entry.open}}},[_vm._v(" "+_vm._s(entry.day)+" - "+_vm._s(entry.totalHours)+" heures "),_c('b-icon',{attrs:{"icon":entry.open ? 'chevron-up' : 'chevron-down'}})],1),_c('b-collapse',{model:{value:(entry.open),callback:function ($$v) {_vm.$set(entry, "open", $$v)},expression:"entry.open"}},[_c('b-card-body',[_vm._l((entry.activities),function(activity,actIdx){return _c('div',{key:actIdx,staticClass:"border rounded p-2 mb-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 mb-2"},[_c('validation-provider',{attrs:{"name":"activity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"activity"}},[_vm._v("Activité")]),_c('v-select',{class:{'is-invalid': errors.length > 0},attrs:{"options":_vm.availableActivities(dayIdx, activity),"label":"label","placeholder":"Choisir une activité"},model:{value:(activity.selectedActivity),callback:function ($$v) {_vm.$set(activity, "selectedActivity", $$v)},expression:"activity.selectedActivity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(activity.selectedActivity && activity.selectedActivity.type === 'project')?_c('div',{staticClass:"col-md-5 mb-2"},[_c('validation-provider',{attrs:{"name":"project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Projet")]),_c('v-select',{class:{'is-invalid': errors.length > 0},attrs:{"options":_vm.projects,"label":"code","placeholder":"Choisissez un projet"},model:{value:(activity.selectedProject),callback:function ($$v) {_vm.$set(activity, "selectedProject", $$v)},expression:"activity.selectedProject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-md-2 mb-2"},[_c('validation-provider',{attrs:{"name":"hour","rules":"required|numeric|min-value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Heures")]),_c('b-form-input',{class:{'is-invalid': errors.length > 0},attrs:{"type":"number","min":"0","placeholder":"Nombre d'heures"},model:{value:(activity.hours),callback:function ($$v) {_vm.$set(activity, "hours", _vm._n($$v))},expression:"activity.hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-5 mb-2"},[_c('label',[_vm._v("Description détaillée")]),_c('b-form-textarea',{attrs:{"placeholder":"Description détaillée...","rows":"2"},model:{value:(activity.description),callback:function ($$v) {_vm.$set(activity, "description", $$v)},expression:"activity.description"}})],1)]),_c('div',{staticClass:"text-right mt-2"},[_c('b-button',{attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeActivity(dayIdx, actIdx)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)])}),_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.addActivity(dayIdx)}}},[_vm._v(" Ajouter activité ")])],2)],1)],1)],1)})],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }